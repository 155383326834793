import React, { useEffect, useState } from 'react';
import BaseDataGridTable from '../../components/tables/BaseDataGridTable';
import { GridColDef } from '@mui/x-data-grid';
import { AppBar, Box, createTheme, PaletteColor, Paper, Typography, useTheme } from '@mui/material';
import { useApi } from '../../services/HttpService';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { themeStore } from '../../components/themes/BaseTheme';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';

interface Members {
  id: number;
  member_number: string;
  first_name: string;
  last_name: string;
  name: string;
}

const mainColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'member_number', headerName: 'Member number', width: 130 },
  { field: 'first_name', headerName: 'First name', width: 130 },
  { field: 'last_name', headerName: 'Last name', width: 130 },
  { field: 'name', headerName: 'Full name', width: 160},
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  // },
];

const subColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'member_number', headerName: 'Member number', width: 130 },
  { field: 'first_name', headerName: 'First name', width: 130 },
  { field: 'last_name', headerName: 'Last name', width: 130 },
  { field: 'name', headerName: 'Full name', width: 160},
];

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: 200 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const ManageMembers = () => {
  const theme = useTheme();
  const {api} = useApi();
  const [members, setMembers] = useState<Members[]>([]);
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [tabIndex, settabIndex] = useState(0);

  const subTableTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: theme.palette.mode === 'dark' ? '#31004a' : '#b9a9d7',
      },
    },
  });

  React.useEffect(() => {
    api("/contacts", "GET").then((res) => {
      if (res) {
        if (res.statusCode === 200) {
          setMembers(res.contacts);
        }
      }
    });
  }, []);

  const handleRowSelectionModelChange = (ids: any) => {
    const newSelectedSet = new Set(ids);
    setSelectedIds(newSelectedSet);
  }

  const selectedRows = members.filter(row => selectedIds.has(row.id));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    settabIndex(newValue);
  };

  return (
    <>
      <Typography variant='h6' sx={{my:2}}>Manage Members</Typography>
      <Box sx={{ m: 1, height: 400, maxWidth: 1800, overflow: 'auto' }}>
        <BaseDataGridTable
          rows={members}
          columns={mainColumns}
          // onRowClick={(row) => { handleRowClick(row) }}
          onRowSelectionModelChange={(ids) => { handleRowSelectionModelChange(ids) }}
        />
      </Box>
      <h3>SELECTED COUNT: {selectedRows.length}</h3>
      <Box sx={{ width: '100%', mt: 2 }}>
        <AppBar position="static" sx={
          {
            backgroundColor: theme.palette.mode === 'dark' ? "inherit" : theme.palette.grey[300],
            color: theme.palette.mode === 'dark' ? "white" : "black"
          }
        }>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            centered
            textColor="inherit"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <Tab label="Item One" sx={{ fontWeight: "bold" }} {...a11yProps(0)} />
            <Tab label="Item Two" sx={{ fontWeight: "bold" }} {...a11yProps(1)} />
            <Tab label="Item Three" sx={{ fontWeight: "bold" }} {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <Box sx={{ p: 3, height: 400 }}>
        {selectedRows.length > 0 ? (
          <>
            <TabPanel value={tabIndex} index={0} dir={theme.direction}>
              Item One
            </TabPanel>
            <TabPanel value={tabIndex} index={1} dir={theme.direction}>
              <BaseDataGridTable
                theme={subTableTheme}
                rows={selectedRows}
                columns={subColumns}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2} dir={theme.direction}>
              Item Three
            </TabPanel>
          </>
        ) : (
          <>
            <Paper elevation={4} sx={{ width: '100%', height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h6'>No rows selected</Typography>
            </Paper>
          </>
        )}
        </Box>
      </Box>
    </>
  )
}